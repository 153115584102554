import React from "react";
import Sidebar from "./components/Sidebar";
import Home from "./components/Home";
import Advertise from "./components/Advertise";
import Team from "./components/Team";
import Contact from "./components/Contact";
import Careers from "./components/Careers";
import Blog from "./components/Blog";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";
import Contribution from "./components/Contribution";
import ModeratorCodeOfConduct from "./components/ModeratorCodeOfConduct";

import { BrowserRouter, Routes, Route} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
  return (

    <BrowserRouter>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800,900" />
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Oswald" />
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Bowlby+One+SC" />
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Protest+Guerrilla" />
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Protest+Revolution" />

    <div className="d-flex flex-column">

      <div class="header">
        <Sidebar/>
      </div>
      <div className="main">
      <Routes className="routes">
        <Route path="/" element={<Home/>}></Route>
        <Route path="/team" element={<Team/>}></Route>
        <Route path="/blog" element={<Blog/>}></Route>
        <Route path="/advertise" element={<Advertise/>}></Route>
        <Route path="/careers" element={<Careers/>}></Route>
        <Route path="/contact" element={<Contact/>}></Route>

        <Route path="/contribution" element={<Contribution/>}></Route>


        <Route path="/privacyPolicy" element={<PrivacyPolicy/>}></Route>
        <Route path="/contentPolicy" element={<ContentPolicy/>}></Route>
        <Route path="/termsOfUse" element={<TermsOfUse/>}></Route>


      </Routes>

      </div>
    </div>

    
    </BrowserRouter>
  );
}

export default App;



function Datasets(){
  return <h2>Datasets</h2>
}

function ContentPolicy(){
  return <h2>Content Policy</h2>
}
