import React from "react"
import 'bootstrap-icons/font/bootstrap-icons.css'
import {Link} from 'react-router-dom'
import "@phosphor-icons/web/light"
import "@phosphor-icons/web/bold"
import YN from "../images/YN.png"
import 'bootstrap/dist/css/bootstrap.min.css'
import Ashinao from "../images/ashinao.png"



function Sidebar(){
    return (
        <div className='sidebar d-flex text-white p-4'>


            <ul class="nav nav-fill nav-pills">
                <li class="nav-item">
                    <a class="nav-link" aria-current="page" href="/">Home</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">About</a>
                    <ul class="dropdown-menu w-100">
                        <li className="lst"><a class="dropdown-item" href="/team">Team</a></li>
                        <li className="lst"><a class="dropdown-item" href="/careers">Careers</a></li>
                        {/*}
                        <li className="lst"><a class="dropdown-item" href="/contribution">Contribution</a></li>
                         */} 
                    </ul>
                </li>

                {/*}
                <li class="nav-item dropdown">
                    <a class="nav-link" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Services</a>
                    <ul class="dropdown-menu w-100">
                        <li className="lst"><a class="dropdown-item" href="/advertise">Advertise</a></li>
                        <li className="lst"><a class="dropdown-item" href="/datasets">Datasets</a></li>
                    </ul>
                </li>
                */} 

          

                <li class="nav-item">
                    <a class="nav-link" aria-current="page" href="/contact">Contact</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Safety</a>
                    <ul class="dropdown-menu w-100">
                        <li className="lst"><a class="dropdown-item" href="/privacyPolicy">Privacy Policy</a></li>
                        <li className="lst"><a class="dropdown-item" href="/termsOfUse">Terms Of Use</a></li>
                    {/*}
                        <li className="lst"><a class="dropdown-item" href="/moderatorCodeOfConduct">Moderator Code Of Conduct</a></li>
                    */} 
                    </ul>
                </li>
    
            </ul>
           

 

      
        </div>
    )
}


/*
function Sidebar(){
    return (
        <div className='sidebar d-flex text-white p-4 vh-100 w-100'>
            <div className="w-100">
                <a href="d-flex align-items-center">
                    <img src={YN} width='70px' height='70px'/>
                   
                </a>
                <hr className='text-secondary mt-2'/>
                <span className='fs-8 text-black'>MAIN</span>
                <ul className='nav flex-column nav-pills'>
                    <li className='nav-item py-1 px-0'>
                        <Link to="/" className='nav-link text-white fw-8 px-2'>
                            <span className='fs-5'>Home</span>
                        </Link>
                    </li>
                    <li className='nav-item py-1'>
                        <Link to="/blog" className='nav-link text-white px-2'>
                            <span className='fs-5'>Blog</span>
                        </Link>
                    </li>

                    <li className='nav-item py-1'>
                        <Link to="/advertise" className='nav-link text-white px-2'>
                            <span className='fs-5'>Advertise</span>
                        </Link>
                    </li>

                    <li className='nav-item py-1'>
                        <Link to="/careers" className='nav-link text-white px-2'>
                            <span className='fs-5'>Careers</span>
                        </Link>
                    </li>


                    <li className='nav-item py-1'>
                        <Link to="/contact" className='nav-link text-white px-2'>
            
                            <span className='fs-5'>Contact</span>
                        </Link>
                    </li>
                </ul>

                <p>
                </p>

                <span className='fs-8 text-black'>SAFETY</span>
                <ul className='nav nav-pills flex-column'>

                    <li className='nav-item py-1'>
                        <Link to="/privacyPolicy" className='nav-link text-white px-2'>
                            <span className='fs-5'>Privacy Policy</span>
                        </Link>
                    </li>

                    <li className='nav-item py-1'>
                        <Link to="/termsOfUse" className='nav-link text-white px-2'>
                            <span className='fs-5'>Terms Of Use</span>
                        </Link>
                    </li>

                    <li className='nav-item py-1'>
                        <Link to="/contentPolicy" className='nav-link text-white px-2'>
                            <span className='fs-5'>Content Policy</span>
                        </Link>
                    </li>

                </ul>


            </div>
        </div>
    )
}
*/
export default Sidebar;