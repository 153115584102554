import React from "react"
import screenshot1 from "../images/screenshot1.png"
import screenshot2 from "../images/screenshot2.png"
import screenshot3 from "../images/screenshot3.png"
import 'bootstrap/dist/css/bootstrap.min.css'

function Blog(){
    return (
  
        
        <div className="container d-flex">
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Oswald" />
            <div class="row" id="subcontainer-row">

            <div class="col-sm-4">
                <br/>
                <h1 >Blog</h1>
            </div>
            <div class="col-sm-8">

                <br/>

                <h3>

                We are not hiring at the moment as we are in the fund raising stage. However in the future we plan on hiring mobile (ios/android) and web developers, 
                UI designers, marketing experts as well as vetters (to regulate who joins the app and to keep YooNuu a safe space). We aim for YooNuu
                to be a 100% run by GASNAD's (gender and sexuality non-conforming africa diasporan). In the meantime, please email us a link to your linkedin page 
                and/or your resume if you feel you can contribute to YooNuu's mission and we will reach out to you in the future.
                </h3>
            </div>
            </div>

        </div>
      
  
  
    );
}
  
export default  Blog;