import React from "react"
import screenshot1 from "../images/screenshot1.png"
import screenshot2 from "../images/screenshot2.png"
import screenshot3 from "../images/screenshot3.png"
import transcription from "../images/transcription.png"
import 'bootstrap/dist/css/bootstrap.min.css'
import YN from "../images/YN.png"
import AlagbaTe from "../images/alagbate.png"
import Ashinao from "../images/ashinao.png"

function Contribution(){
    return (
  
        
        <div className="container-fluid" >
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Freeman" />
            <div className="subcontainer">
  

                <div class="row justify-content-md-center" id="subcontainer-row">
              
          
                    <div class="col-sm" >

                        <div className="txt">
                            <br/>
                    
                            <div className="heading1" >Tasks</div>
                            <div className="heading2" > We are building datasets that will be used to develop artificial Intelligence models to solve problems in natural 
                            language processing (language translation, information retrieval, speech recognition) and computer vision (optical character recognition).  
                            </div>
                            <br/>
                            <br/>
                            <div className="heading1" >Forum Posts</div>
                            <div className="heading2" >Your helpful responses to posts in YooNuu's discussion forum will allow us to build datasets that can be used to develop artificial intelligence 
                            based question-answering agents.
                            </div>
                            <br/>
                            <br/>
                            <div className="heading1" >Contribution Score</div>
                            <div className="heading2" >You will be assigned a score based on the quality of your forum posts and solutions to the tasks.
                            This contribution score will show up on your profile
                            </div>

                        </div>
                    </div>
                   
                    <div class="col-sm " >
                        <img id="phone" src={transcription} alt="" width="400" class="d-block m-auto"/>
                    </div>
            
       
    
                </div> 

            </div>

            

        </div>
      
  
  
    );
}
  
export default Contribution;