import React from "react"
import screenshot1 from "../images/screenshot1.png"
import screenshot2 from "../images/screenshot2.png"
import screenshot3 from "../images/screenshot3.png"
import 'bootstrap/dist/css/bootstrap.min.css'
import YN from "../images/YN.png"
import AlagbaTe from "../images/alagbate.png"
import Ashinao from "../images/ashinao.png"

function Advertise(){
    return (
  
        
        <div className="container-fluid" >
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Freeman" />
            <div className="subcontainer">
  

                <div class="row" id="subcontainer-row">
              
          
                    <div class="col-sm" >

                        <div className="txt">
                            <br/>
                    
                            <div className="heading1" >Out in Front</div>
                            <div className="heading2" >Our full screen ad features an inbox alert for a powerful combination that gets your message noticed. Your full screen ad is guaranteed to look perfect on every phone and tablet, and the accompanying alert message allows users to repeatedly recall the ad.</div>
                            <br/>
                            <br/>
                            <div className="heading1" >Clutter-free</div>
                            <div className="heading2" >We have no third party ad-networks, so your campaign won’t compete with distracting banners. Our users trust us to deliver them relevant advertising on a secure platform, which cultivates a bespoke environment for our clients to showcase their offerings.</div>
                            <br/>
                            <br/>
                            <div className="heading1" >Dedicated Service</div>
                            <div className="heading2" >Our experienced advertising team will help you plan, design, and execute a campaign that suits your budget and goals.</div>

                        </div>
                    </div>
                   
                    <div class="col-sm " >
                        <img id="phone" src={screenshot1} alt="" width="400" class="d-block m-auto"/>
                    </div>
            
       
    
                </div> 

            </div>

            

        </div>
      
  
  
    );
}
  
export default Advertise;