import React from "react"
import screenshot1 from "../images/screenshot1.png"
import screenshot2 from "../images/screenshot2.png"
import screenshot3 from "../images/screenshot3.png"
import 'bootstrap/dist/css/bootstrap.min.css'
import YN from "../images/YN.png"
import AlagbaTe from "../images/alagbate.png"
import Ashinao from "../images/ashinao.png"
import AfricanMask from "../images/africanmask.png"
import AfricanMask2 from "../images/africanmask2.png"
import AfricanMask3 from "../images/africanmask3.png"
import { SocialIcon } from 'react-social-icons'


function Home(){
    return (
  
        
        <div className="container-fluid" >

            <div className="subcontainer">


                <div class="row" id="subcontainer-row">
                    <div class="col-sm" >
                        <div className="txt">

                            {/*}

                           <div className="logo">
                            <img src={AfricanMask2} alt=""/>
                           </div>
                            */}


                            <br/>

                            <br/>

                            <div className="heading1">YooNuu</div>
                         
                            <div className="heading2" >Join our growing network of <i>vetted</i> GASNA (Gender and Sexuality Non-Conforming Africans) and connect with activists, professionals and academics around 
                            the world.
                            </div>

                            <br/>

                            <br/>
                        


      
                            <div className="heading1" >Follow Us</div>

                            <br/>
                        


                            <div class="row">

                            <div class="col-2"><SocialIcon url="https://www.linkedin.com/in/carlashieannan/" bgColor="black" fgColor="white"/></div>

                            <div class="col-2"><SocialIcon url="https://www.instagram.com/nungua.nuu/" bgColor="black" fgColor="white"/></div>

                            <div class="col-2"><SocialIcon url="https://x.com/vegan_engineer" bgColor="black" fgColor="white"/></div>

                            <div class="col-2"><SocialIcon url="https://www.facebook.com/profile.php?id=61559373999616" bgColor="black" fgColor="white"/></div>

                            </div>

                            <br/>
                    
                  
                            <br/>
                      
                       
                                            
                        </div>
                    </div>
                   
                    <div class="col-sm" >
                        <br/>

                        <img id="phone" src={screenshot3} alt="" width="400" class="d-block m-auto"/>
                        <h4 className="download"><b>Download iOS App</b></h4>
                        <p className="download">Available Soon on AppStore</p>
                    </div>
            
       
    
                </div> 

            </div>

            

        </div>
      
  
  
    );
}
  
export default Home;